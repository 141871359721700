<template>
  <div class="car">
    <div v-if="carList.length < 1"
         style="margin-bottom:30px;">
      <temp-data :shouwtext="'购物车暂无商品！'"></temp-data>
    </div>
    <div v-else>
      <div class="shoplist">
        <table cellspacing="0"
               style="border: 1px solid #dfdfdf;margin-top:30px;">
          <tr>
            <th style="text-align:left;"><span style="display:inline-block;width:87px;"></span> 商品名称</th>
            <th style="width:145px;">单价</th>
            <th style="width:145px;">数量</th>
            <th style="width:145px;">总价</th>
            <th style="width:145px;">操作</th>
          </tr>
          <tr v-for="(im,ix) in carList"
              :key="ix">
            <td>
              <div class="flex row alignCenter marginAuto">
                <div class="noCurr cursorP flex spacearound alignCenter"
                     @click="checkShop(ix)">
                  <i v-show="currShop.includes(ix)"
                     class="el-icon-check"
                     style="color:#F03708;font-size:15px;"></i>
                </div>
                <div class="tableImg"
                     @click="goother({path:'/shopdetail', query:{id: im.goods_id}})">
                  <img :src="$store.getters.Baseurl  + im.cover_map"
                       alt="">
                </div>
                <div class="shopname moreHidden cursorP"
                     @click="goother({path:'/shopdetail', query:{id: im.goods_id}})">{{im.name}}</div>
              </div>
            </td>
            <td style="color:#F03708">{{im.integral}}积分 + ￥{{im.price}}</td>
            <td>
              <div class="flex alignCenter marginAuto"
                   style="width:90px;">
                <div @click="editNumber(im, 1)"
                     class="addBtn flex alignCenter spacearound cursorP"><i class="el-icon-plus fontW"></i></div>
                <div style="width:40px;">{{im.num}}</div>
                <div @click="editNumber(im, 2)"
                     class="addBtn flex alignCenter spacearound cursorP"><i class="el-icon-minus fontW"></i></div>
              </div>
            </td>
            <td style="color:#F03708">{{im.integral * im.num | tofixed()}}积分 + ￥{{im.price * im.num | tofixed()}}</td>
            <td style="color:#F03708"
                class="cursorP"
                @click="removeshop(im,ix)">删除</td>
          </tr>
        </table>
      </div>
      <div class="carBottom centerWidth marginAuto flex alignCenter spacebetween">
        <div class="carBottomLeft flex alignCenter c33"
             style="font-size:15px;">
          <div class="noCurr cursorP flex spacearound alignCenter"
               style="width:13px;height:13px;margin-left:17px;margin-right:15px;    border: 1px solid #c5c5c5;"
               @click="allCheck">
            <i v-show="currShop.length == carList.length"
               class="el-icon-check"
               style="color:#F03708;font-size:15px;"></i>
          </div>
          <span class="cursorP"
                @click="allCheck">全选</span>
          <span style="color:#F50505;font-size:15px;padding-left:35px;"
                class="cursorP"
                @click="removeS">批量删除</span>
        </div>
        <div class="carBottomRight">
          <span style="font-size:16px;"
                class="c33 fontW">展品总价：{{calcTotal()}}</span>
          <button class="cursorP"
                  @click="account">结算</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get_goods_car, edit_goods_car, del_goods_car } from '@/utils/Api/shop'
import tempData from '@/components/tempData'
export default {
  name: 'goodsCar',
  data () {
    return {
      carParams: {
        page: 1,
        limit: 99999
      },
      nextload: false,
      carList: [],             // 购物车列表
      currShop: [],            // 选中的商品
    }
  },
  created () {
    this.initial()
  },
  methods: {
    /* 结算 */
    account () {
      if (this.currShop.length < 1) {
        this.$message.warning('请选择商品！')
        return
      }
      let vueTempArr = []
      this.currShop.forEach(im => {
        vueTempArr.push(this.carList[im].id)
      })
      // this.$store.commit('common/SET_ACCOUNTSHOP', vueTempArr)
      this.goother({ path: '/goodslist/confirmorder', query: { ids: vueTempArr.join(','), s: 2 } })
    },
    /* 全选 */
    allCheck () {
      let isAll = this.currShop.length == this.carList.length
      if (isAll) { this.currShop = []; return }
      this.currShop = []
      for (let i = 0; i < this.carList.length; i++) {
        this.currShop.push(i)
      }
    },
    /* 多个 删除产品 */
    removeS () {
      this.$confirm('是否删除这些商品？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(res => {
        let arr = []
        this.currShop.forEach((im, ix) => {
          arr.push(this.carList[im].id)
        });
        this.removeShop(arr.join(','), this.currShop)
        this.$store.dispatch('common/getCount')
      })
    },
    /* 单个 删除商品 */
    removeshop (im, ix) {
      this.$confirm('是否删除此商品？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(res => {
        this.removeShop([im.id].join(','), [ix])
        this.$store.dispatch('common/getCount')
      })
    },
    /* 删除商品 */
    async removeShop (value, index) {
      let res = await del_goods_car({ id_arr: value })
      if (res.length < 1) {
        index.reverse().forEach((im, ix) => {
          this.carList.splice(im, 1)
        })
        this.$store.dispatch('common/getCount')
        this.$message.success('删除成功！')
        return
      }
      this.$message.error('删除失败！')
    },
    /* 数量变更 */
    async editNumber (val, type) {
      if (val.num < 2 && type == 2) { return }
      if (type == 1 && val.stock_num <= val.num) return this.$message.error('添加数量超过库存数!')
      let res = await edit_goods_car({ type: type, id: val.id, num: 1 })
      if (res.length < 1) {
        this.$store.dispatch('common/getCount')
        if (type == 1) {
          val.num++
          return
        }
        val.num--
        return
      }
      let text = type == 2 ? '数量减少' : '数量增加'
      this.$message.error(text + '失败')
    },
    /* 计算总价 */
    calcTotal () {
      try {
        let integral = 0, money = 0
        this.currShop.forEach((im, ix) => {
          integral += parseFloat(this.carList[im].integral) * parseFloat(this.carList[im].num)
          money += parseFloat(this.carList[im].price) * parseFloat(this.carList[im].num)
        })
        return `积分：${integral.toFixed(2)}  金额：￥${money.toFixed(2)}`
      } catch (e) { }
    },
    /* 选中商品 */
    checkShop (ix) {
      const isHas = this.currShop.indexOf(ix)
      if (isHas == -1) {
        this.currShop.push(ix)
        return
      }
      this.currShop.splice(isHas, 1)
    },
    /* 获取商品 */
    async initial () {
      this.nextload = true
      let res = await get_goods_car(this.carParams)
      this.carList.push(...res)
      if (res.length < this.carParams.limit) {
        this.nextload = false
        return
      }
      this.carParams.page++
    }
  },
  filters: {
    tofixed (val) {
      return val.toFixed(2)
    }
  },
  components: {
    tempData
  }
}
</script>
<style lang="scss" scoped>
.carBottomRight {
  button {
    width: 123px;
    height: 51px;
    background: #47d7e3;
    font-size: 19px;
    color: #ffffff;
    border-radius: 5px;
    margin-left: 43px;
  }
}
.carBottom {
  height: 50px;
  background: #f7f7f7;
  margin-top: 25px;
  position: sticky;
  bottom: 00px;
}
.addBtn {
  width: 22px;
  height: 23px;
  opacity: 1;
  background: #f1f1f1;
  border-radius: 50%;
  font-size: 16px;
  font-weight: bold;
}
.car {
  .shoplist {
    .noCurr {
      width: 15px;
      height: 14px;
      border: 1px solid #c5c5c5;
      margin-left: 15px;
    }
    .tableImg {
      width: 101px;
      height: 101px;
      margin-left: 20px;
      margin-right: 30px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    table {
      width: 1200px;
      tr {
        th {
          background: #f9fafc;
          height: 46px;
          font-size: 14px;
          color: #333333;
          border-bottom: 1px solid #dfdfdf;
        }
        td {
          font-size: 14px;
          height: 136px;
          border-bottom: 1px solid #dfdfdf;
        }
        &:last-child {
          td {
            border: none;
          }
        }
      }
    }
  }
}
</style>